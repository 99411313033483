/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import { useState } from "react";
import "./index.scss";

const Home = (): JSX.Element => {
  const [langType, setLangType] = useState('en')
  return (
    <>
      <div className="home">
        <div id="main">
          <div className="header">
            <div className="logo">
              <img src={require('../../assets/img/logo.png').default} alt="logo" />
            </div>
            <div className="lang">
              <div className="lang_box">
                <div
                  className={['item', langType === 'en' ? 'active' : ''].join(' ')}
                  onClick={() => {
                    setLangType('en')
                  }}
                >en</div>
                <div
                  className={['item', langType === 'vn' ? 'active' : ''].join(' ')}
                  onClick={() => {
                    setLangType('vn')
                  }}
                >VN</div>
              </div>
            </div>
          </div>
          <div className="top">
            <div className="top-model"> </div>
            <div className="bottom-model"></div>
            <div className="text-logo">
              <img src={require('../../assets/img/text-logo.png').default} alt="" />
            </div>
            <div className="people">
              <img src={require('../../assets/img/people.png').default} alt="" />
            </div>
            <div
              className="car"
              onClick={() => {
                window.open('https://mall.arrayfi.tech/', '_blank');

              }}
            >
              <img src={require('../../assets/img/car.png').default} className='car_icon' alt="" />
              <img className="logo" src={require('../../assets/img/logo.png').default} alt="logo" />
            </div>
            <div className="join">
              <div className="btn">
                {
                  langType === 'en'
                    ? <span>Join Community</span>
                    : <span>Nhập vào cộng đồng</span>
                }

              </div>
            </div>
            <div className="social">
              <button
                className="item"
                onClick={() => {
                  window.open('https://t.me/LizaCommunity', '_blank');
                }}
              >
                <img src={require('../../assets/img/Telegram.png').default} alt="Telegram" />
                Telegram
              </button>
              <button
                className="item"
                onClick={() => {
                  window.open('https://www.facebook.com/groups/942661267046663', '_blank');
                }}
              >
                <img src={require('../../assets/img/facebook.png').default} alt="Facebook" />
                Facebook
              </button>
            </div>
            <div className="text">


              {
                langType === 'en'
                  ? <span>Exclusive Benefits in the <br /> Liza Community</span>
                  : <span>Những phúc lợi độc quyền của <br /> cộng đồng Liza </span>
              }
            </div>
          </div>
          <div className="footer">
            <img src={require('../../assets/img/logo.png').default} alt="logo" />
          </div>
        </div>
      </div>


    </>
  );
}

export default Home;
